import BestPickBackground from '../../../../images/best-pick-bg.svg';
import { Colors } from '../../../../themes/colors';

export const BestPickLabel = () => (
  <div
    style={{
      position: 'absolute',
      top: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      zIndex: 1,
    }}
  >
    <img
      src={BestPickBackground}
      alt="best-pick"
      style={{ position: 'absolute', top: 0, right: 0 }}
    />
    <span
      className="global_font f-16 bold"
      style={{ position: 'relative', zIndex: 2, color: Colors.white, paddingRight: 9 }}
    >
      Best Pick
    </span>
  </div>
);
