import FingerprintJS from '@fingerprintjs/fingerprintjs';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { getShouldUsingCredentialServiceEndpoint } from '../growthbook';
import { store } from '../store/configureStore';
import { apiMethodsConst } from './methods';

const tempMeeApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

tempMeeApi.interceptors.request.use((config) => ({
  ...config,
  headers: {
    ...{ 'x-correlation-id': uuid(), platform: 'web' },
    ...config.headers,
  },
}));

async function getAuthHeaders() {
  if (!store.getState().auth.expirationDate || store.getState().auth.expirationDate < new Date()) {
    const response = await callApi('POST', '/auth/refresh', {
      token: localStorage.getItem('refresh_token_dental'),
    });
    store.dispatch({
      type: apiMethodsConst.AUTH_REFRESH_REQUEST,
      payload: response,
    });
  }
  const { accessToken } = store.getState().auth;
  const { user, selectedOfficeId } = store.getState().user;
  if (user && selectedOfficeId && selectedOfficeId !== user.id) {
    return {
      Authorization: accessToken,
      // timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      'x-office': selectedOfficeId,
    };
  }
  return {
    Authorization: accessToken,
    // timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
}

async function getAuthPPHeaders() {
  if (!store.getState().auth.expirationDate || store.getState().auth.expirationDate < new Date()) {
    const response = await callApi('POST', '/auth/refresh', {
      token: localStorage.getItem('refresh_token_dental'),
    });
    store.dispatch({
      type: apiMethodsConst.AUTH_REFRESH_REQUEST,
      payload: response,
    });
  }
  const { accessToken } = store.getState().auth;
  return {
    Authorization: accessToken,
  };
}

const getHeaders = async (url) => {
  const isAuthRequest =
    (url.includes('/auth/') || url.includes('/users/password')) &&
    url !== '/auth/me' &&
    url !== '/auth/logout';

  if (!isAuthRequest) {
    return getAuthHeaders();
  }

  if (url.includes('/auth/refresh')) {
    const fingerprint = await FingerprintJS.load();
    const { visitorId } = await fingerprint.get();
    return { 'x-device-fingerprint': visitorId };
  }

  return {};
};

async function callApi(method, url, body = null, params, extraHeaders) {
  // get store out of the scope of react nad get headers
  const authHeaders = await getHeaders(url);
  const data = method === 'GET' ? { params: body } : { data: body };
  const response = await tempMeeApi({
    method,
    url,
    ...data,
    headers: { ...authHeaders, ...extraHeaders },
    params,
  });
  return response.data;
}

async function callApiReturnParam(method, url, body = null, params) {
  // get store out of the scope of react nad get headers
  const isAuthRequest = url.includes('/auth/') && url !== '/auth/me' && url !== '/auth/logout';
  const authHeaders = !isAuthRequest ? await getAuthHeaders() : {};
  const data = method === 'GET' ? { params: body } : { data: body };
  const response = await tempMeeApi({
    method,
    url,
    ...data,
    headers: authHeaders,
    params,
  });
  return body;
}

async function callApiWithValidate(method, url, params) {
  // get store out of the scope of react nad get headers
  const headers = await getAuthHeaders();
  const response = await tempMeeApi({
    method,
    url,
    params,
    headers,
    validateStatus: (status) => (status >= 200 && status < 300) || status === 400,
  });

  return response;
}

async function callPPApi(method, url, body = null, params, isPostedApplicationId) {
  // get store out of the scope of react nad get headers
  const authHeaders = await getAuthPPHeaders();
  const data = method === 'GET' ? { params: body } : { data: body };
  const response = await tempMeeApi({
    method,
    url,
    ...data,
    headers: authHeaders,
    params,
  });
  return isPostedApplicationId
    ? { applicationId: isPostedApplicationId, lastApplicationAction: response.data }
    : response.data;
}

async function createFirebaseLink(referralId) {
  const body = {
    link: `${process.env.REACT_APP_DOMAIN_URI_PREFIX}/referral?referalId=${referralId}`,
    domainUriPrefix: process.env.REACT_APP_DOMAIN_URI_PREFIX,
    iosInfo: {
      appStoreId: process.env.APP_STORE_ID,
      iosBundleId: process.env.APP_BUNDLE_ID,
    },
    androidInfo: {
      androidPackageName: 'com.tempmeeapp',
    },
  };
  const link = await axios.post(
    `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_FIREBASE_APIKEY}`,
    { dynamicLinkInfo: body },
  );

  return link.data;
}

const signInRequest = ({ email, password }) => callApi('POST', '/auth/signin', { email, password });
const authMeRequest = () => callApi('GET', '/auth/me'); // TODO: - change to /users/me
const authSignUpRequest = ({ email, password, referral_id }) =>
  callApi('POST', '/auth/signup', {
    email,
    password,
    original: 'web-portal',
    referral_id,
  });
const authLogoutRequest = () => callApi('POST', '/auth/logout');
const authRefreshRequest = (token) => callApi('POST', '/auth/refresh', { token });
const forgotPasswordSendCodeRequest = (data) => callApi('PUT', '/users/password', data);
const forgotPasswordResetRequest = (data) => callApi('POST', '/users/password/confirm', data);
const editUserRequest = (data) => callApi('PATCH', '/users', data);
const addAddressRequest = (place_id) =>
  callApi('POST', '/user/address', { place_id, distance: 25 });
const addOfficeProductivityRequest = (productivity) =>
  callApi('PATCH', '/users/office-productivity', { productivity });
const phoneVerificationRequest = (code) => callApi('POST', '/users/phone/confirm', { code });
const sendSMSCodeRequest = (phone) => callApi('PUT', '/users/phone', { phone });
const deleteAddressesRequest = () => callApi('DELETE', '/user/address');
const blockProfessionalRequest = ({ userId }) =>
  callApi('POST', `/users/${userId}/addBlocked`, { userId });
const feedbackRequest = () => callApi('GET', '/feedback/required');
const feedbackSaveRequest = ({ data, jobId }) => callApi('POST', `/review/job/${jobId}`, data);
const sendProcedureEndorsementRequest = ({ endorsement, userId }) =>
  callApi('POST', `/credential-service/users/${userId}/credentials/reviews`, endorsement);
const fetchUserProcedureEndorsementRequest = ({ candidateId, profession }) =>
  callApi('GET', `/credential-service/users/${candidateId}/credentials`, null, { profession });

const saveNpsRequest = (score) => callApi('POST', '/nps-review', { score });
const saveTempMeeFeedbackRequest = (message) => callApi('POST', '/feedback/tempmee', { message });
const getAllProfessionsRequest = () => callApi('GET', '/professions');
const getAllSpecializationsRequest = () => callApi('GET', '/specializations');

/**
 * Getting user's education. Contains user's specialties, procedures, insurances and softwares
 *
 * Use {@link getShouldUsingCredentialServiceEndpoint} feature flag for switching between old and new endpoints.
 *
 * @param {UserProfession} profession
 * @returns {Promise<any>}
 */
const getUserEducationRequest = ({ profession }) => {
  const professionType = profession || 'RDH';

  if (getShouldUsingCredentialServiceEndpoint()) {
    return callApi(
      'GET',
      `/credential-service/credentials?userType=HYG&profession=${professionType}`,
    );
  }

  // replaced from wrong access from 'DNT' to 'HYG' and eliminates `.DNT` key access
  // to use `.[profession]` key access
  return callApi('GET', `/users/education?userType=HYG&profession=${professionType}`);
};

/**
 * Getting office's education. Contains user's specialties, procedures, insurances and softwares
 *
 * Use {@link getShouldUsingCredentialServiceEndpoint} feature flag for switching between old and new endpoints.
 *
 * @returns {Promise<any>}
 */
const getOfficeEducationRequest = () => {
  if (getShouldUsingCredentialServiceEndpoint()) {
    return callApi('GET', '/credential-service/credentials?userType=DNT');
  }

  // profession need to be declared otherwise returns error
  return callApi('GET', `/users/education?userType=DNT&profession=RDH`);
};
const updateUserEducationRequest = (data) =>
  callApi('PATCH', '/users/specialtiesAndProcedures', data);
const getAllSoftwaresRequest = () => callApi('GET', '/softwares');
const acceptTermsRequest = () => callApi('POST', '/user/tos/gotu');
const acceptPSATermsRequest = () => callApi('POST', '/user/tos/psa');
const uploadAvatarRequest = (data) =>
  callApi('PATCH', '/user/avatar', data, null, { 'Content-Type': 'multipart/form-data' });

// payment requests
const getAllPaymentsRequest = () => callApi('GET', '/payment/account/payment-method/');
const addPaymentRequest = (data) => callApi('POST', '/payment/account/payment-method/', data);
const deletePaymentRequest = (paymentId) =>
  callApi('DELETE', `/payment/account/payment-method/${paymentId}`);
const selectPaymentRequest = (paymentId) =>
  callApi('PATCH', `/payment/account/payment-method/${paymentId}`, {});
const verifyBankRequest = ({ paymentId, data }) =>
  callApi('POST', `/paymentMethods/${paymentId}/verify`, { amounts: data });
const createPlaidToken = () => callApi('POST', '/payment/account/plaid/', {});
const submitPlaidToken = (body) => callApi('POST', '/payment/account/plaid/connect', body);
// job
const createJobRequest = (data) => callApi('POST', '/jobs', data);
const fetchJobRequest = (jobId) => callApi('GET', `/jobs/${jobId}`);
const fetchJobCandidatesRequest = (jobId) => callApi('GET', `/jobs/${jobId}/candidates`);
const fetchCandidateOvertimeRequest = ({ jobId, candidateId }) =>
  callApi('GET', `jobs/${jobId}/candidate/${candidateId}`);
const getUserOvertimeLimitsRequest = () => callApi('GET', `/users/overtime-limits`);
const editJobRequest = ({ jobId, jobData }) => callApi('PATCH', `/jobs/${jobId}`, jobData);
const getAllJobsRequest = (data) => callApi('GET', '/jobs/count-and-dates', {}, data);
const getJobsRequestByStatusDate = ({ date, status, page = 1, limit = 10 }) =>
  callApi(
    'GET',
    '/jobs',
    {},
    date
      ? {
        date,
        sortField: 'job_datetime',
        sortOrder: 'asc',
        page,
        limit,
      }
      : {
        status,
        sortField: 'job_datetime',
        sortOrder: status === 'all' || status === 'past' ? 'desc' : 'asc',
        page,
        limit,
      },
  );
const getLastPostedJobsRequest = () => callApi('GET', '/jobs', {}, {
  sortField: 'createdAt',
  sortOrder: 'desc',
  status: 'all',
  limit: 1,
  page: 1,
});
const fetchJobsWithTransactions = (data) => callApi('GET', '/jobs-with-transactions', {}, data);
const cancelJobRequest = ({ jobId }) => callApi('POST', `/jobs/${jobId}/cancel`);
const confirmJobRequest = ({ jobId, data, counterOfferId }) =>
  callApi('POST', `/jobs/${jobId}/confirm/${counterOfferId}`, data);
const reportIncidentRequest = ({ jobId, type }) =>
  callApi('POST', `/jobs/${jobId}/incidents`, { type });
const multidayJobPostingRequest = (data) => callApi('POST', '/multi-day', data);
const fetchOpenJobRequest = (data) => callApi('GET', 'jobs/dashboard/open', {}, data);
const fetchScheduledJobsRequest = (data) => callApi('GET', 'jobs/dashboard/filled', {}, data);
const fetchActionRequiredJobsRequest = (data) =>
  callApi('GET', 'jobs/dashboard/action-required', {}, data);
const fetchTodayJobsRequest = (data) => callApi('GET', 'jobs/dashboard/today', {}, data);
const fetchExpiredCounterOffersRequest = (jobId) =>
  callApi('GET', `offers/${jobId}/declined-expired`);
const fetchDashboardInfoRequest = () => callApi('GET', 'jobs/dashboard');

const declineCounterOffer = ({ jobId, counterOfferId, cancellation_reason }) =>
  callApi('DELETE', `jobs/${jobId}/offers/${counterOfferId}`, { cancellation_reason });
const adjustHoursRequest = ({ jobId, data }) => callApi('POST', `jobs/${jobId}/adjust`, data);
const fetchAverageRatingRequest = () => callApi('GET', 'jobs/average-rate');
const fetchCancellationReasonsRequest = () => callApi('GET', 'cancellation-reasons');
const setCancellationReasonsRequest = ({ jobId, data }) =>
  callApi('POST', `/jobs/${jobId}/cancellationFeedback`, data);

// alerts
const getAlertsRequest = (state) => callApi('GET', '/alerts', {}, state);
const deleteAlertsRequest = () => callApi('DELETE', '/alerts/clear');
const deleteOneAlertRequest = (alertId) => callApi('DELETE', `/alerts/${alertId}/read`);
// messages
const getMessagesRequest = (state) => callApi('GET', '/conversations', {}, state);
const getMessagesByJobIdRequest = (jobId) => callApi('GET', `/jobs/${jobId}/messages`);
const postMessageRequest = ({ jobId, message }) =>
  callApi('POST', `/jobs/${jobId}/messages`, { text: message });
const inviteAdminRequest = (jobId) => callApi('POST', `/jobs/${jobId}/invite-admin`, {});
// DSO
const getSelectedOfficeProfile = () => callApi('GET', '/auth/me');

// receipts
const fetchReceiptsRequest = (state) => callApi('GET', '/receipts/dashboard', {}, state);
const fetchReceiptsReportRequest = (state) => callApi('GET', '/receipts/report', {}, state);
// invoice
const fetchInvoicesRequest = (state) => callApi('GET', '/invoices', {}, state);

// PP
const ppbPostingRequest = (data) => callPPApi('POST', '/pp/jobs', data);

const fetchAllPPJobsRequest = () => callPPApi('GET', '/pp/jobs?page=1&limit=10');

const fetchPPApplicationsRequest = async ({ jobId }) => callPPApi('GET', `/pp/jobs/${jobId}`);

const fetchPPApplicationByIdRequest = ({ jobId, applicationId }) =>
  callPPApi('GET', `/pp/jobs/${jobId}/applications/${applicationId}`);
const postPPActionnRequest = ({ jobId, actionType, applicationId, notes }) =>
  callPPApi(
    'POST',
    `/pp/jobs/${jobId}/applications/${applicationId}/action`,
    { action: actionType, notes },
    {},
    applicationId,
  );

// referrals
const saveGiftEmailRequest = (email) =>
  callApi('PATCH', '/users/gift-email', { gift_email: email });
const getReferralRequest = (type) => callApiWithValidate('GET', `referral/${type}`, { type });
const createReferralRequest = (type) => callApi('POST', 'referral', { type }, { type });
const putReferralLinkRequest = (referralId, referralLink, type) =>
  callApi('PUT', `referral/${referralId}/url`, { id: referralId, url: referralLink }, { type });
const createFirebaseLinkRequest = (referralId) => createFirebaseLink(referralId);

// adjustments
const fetchAdjustmentsRequest = () => callApi('GET', '/adjustments/required');
const changeStatusAdjustmentsRequest = ({ jobId, adjustmentId, status, disputeReason }) =>
  callApi('PATCH', `/jobs/${jobId}/adjust/${adjustmentId}`, { status, disputeReason });

// locum tenens dentist
const createLocumTenensDentistRequest = (text) => callApi('POST', '/locum-tenens', { text });
// notifications
const fetchNotificationSettingsRequest = () =>
  callApi('GET', '/notification-sender/notification-settings');
const updateNotificationSettingsRequest = (notificationSettings) =>
  callApi('PATCH', '/notification-sender/notification-settings', notificationSettings);

// favorites
const fetchFavoritesProfessionalRequest = (params) =>
  callApi('GET', '/favorite-professionals', {}, params || {});
const deleteFavoriteProfessionalRequest = (id) =>
  callApi('DELETE', `/favorite-professionals/${id}`);
const addFavoriteProfessionalRequest = (id) =>
  callApiReturnParam('PATCH', '/favorite-professionals', { userIds: [id] });
const inviteFavoriteToJobRequest = ({ jobId, userIds }) =>
  callApi('POST', `/jobs/${jobId}/invites`, { userIds });
const inviteProfessionalsToJobsRequest = ({ jobIds, professionalIds }) =>
  callApi('POST', `/jobs/invites`, { jobIds, professionalIds });

const fetchRecentProfessionalsRequest = (params) =>
  callApi('GET', '/users/recent-professionals', {}, params || {});
const fetchOpenJobsForRecentProfessionalRequest = ({ professionalId, ...params }) =>
  callApi('GET', `/offer-discovery/professionals/${professionalId}/offers`, {}, { ...params });

const fetchHighlyRatedProfessionalsRequest = (params) =>
  callApi('GET', '/users/highly-rated', {}, params || {});

// invites
const fetchOfficeJobInvitesRequest = (params) => callApi('GET', `/jobs/invites`, {}, params || {});

// partnership code
const fetchPartnershipOrganizationsRequest = () => callApi('GET', '/partnership-code/organization');
const verifyPartnershipCodeRequest = (data) => callApi('PATCH', '/users', data);

// metadata
const fetchMetadataRequest = () => callApi('GET', '/metadata');

// worker type
const saveWorkerClassificationRequest = (type) =>
  callApi('PATCH', '/users/worker-classification', type);
const saveChildOfficeWorkerClassification = (params) =>
  callApi('PATCH', `/users/${params.id}/worker-classification`, { type: params.workerType });
const saveStatusChildOfficeWorkerClassification = (params) =>
  callApi('POST', `/users/worker-classification/${params.id}`, { status: params.status });
const getStateWorkerClassificationRequest = () =>
  callApi('GET', '/users/state-worker-classification');
const getWorkerClassificationManagement = () => callApi('GET', '/users/worker-classification');

export const apiMethods = {
  signInRequest,
  authMeRequest,
  authSignUpRequest,
  authLogoutRequest,
  forgotPasswordSendCodeRequest,
  forgotPasswordResetRequest,
  editUserRequest,
  addAddressRequest,
  addOfficeProductivityRequest,
  phoneVerificationRequest,
  sendSMSCodeRequest,
  deleteAddressesRequest,
  blockProfessionalRequest,
  feedbackRequest,
  authRefreshRequest,
  feedbackSaveRequest,
  sendProcedureEndorsementRequest,
  fetchUserProcedureEndorsementRequest,
  saveNpsRequest,
  saveTempMeeFeedbackRequest,
  acceptTermsRequest,
  acceptPSATermsRequest,
  uploadAvatarRequest,
  // paymnet
  selectPaymentRequest,
  deletePaymentRequest,
  addPaymentRequest,
  getAllPaymentsRequest,
  verifyBankRequest,
  createPlaidToken,
  submitPlaidToken,
  // job
  fetchAverageRatingRequest,
  createJobRequest,
  getAllJobsRequest,
  editJobRequest,
  fetchJobRequest,
  fetchJobCandidatesRequest,
  fetchCandidateOvertimeRequest,
  getUserOvertimeLimitsRequest,
  cancelJobRequest,
  confirmJobRequest,
  reportIncidentRequest,
  getJobsRequestByStatusDate,
  fetchJobsWithTransactions,
  multidayJobPostingRequest,
  ppbPostingRequest,
  fetchOpenJobRequest,
  fetchScheduledJobsRequest,
  fetchActionRequiredJobsRequest,
  fetchTodayJobsRequest,
  declineCounterOffer,
  fetchExpiredCounterOffersRequest,
  fetchDashboardInfoRequest,
  adjustHoursRequest,
  fetchCancellationReasonsRequest,
  setCancellationReasonsRequest,
  getLastPostedJobsRequest,

  // alerts
  getAlertsRequest,
  deleteAlertsRequest,
  deleteOneAlertRequest,
  getAllProfessionsRequest,
  getAllSpecializationsRequest,
  getUserEducationRequest,
  getOfficeEducationRequest,
  updateUserEducationRequest,
  getAllSoftwaresRequest,
  // messages
  getMessagesRequest,
  getMessagesByJobIdRequest,
  postMessageRequest,
  inviteAdminRequest,
  // dso
  getSelectedOfficeProfile,
  // receipts
  fetchReceiptsRequest,
  fetchReceiptsReportRequest,
  // invoice
  fetchInvoicesRequest,
  // pp
  fetchAllPPJobsRequest,
  fetchPPApplicationByIdRequest,
  postPPActionnRequest,
  fetchPPApplicationsRequest,
  // referrals
  saveGiftEmailRequest,
  getReferralRequest,
  createReferralRequest,
  putReferralLinkRequest,
  createFirebaseLinkRequest,
  // adjustmants
  fetchAdjustmentsRequest,
  changeStatusAdjustmentsRequest,
  // locum tenens dentist
  createLocumTenensDentistRequest,
  // notifications
  fetchNotificationSettingsRequest,
  updateNotificationSettingsRequest,
  // favorites
  fetchFavoritesProfessionalRequest,
  deleteFavoriteProfessionalRequest,
  addFavoriteProfessionalRequest,
  inviteFavoriteToJobRequest,
  inviteProfessionalsToJobsRequest,
  fetchRecentProfessionalsRequest,
  fetchOpenJobsForRecentProfessionalRequest,
  // invites
  fetchOfficeJobInvitesRequest,
  // partnership code
  fetchPartnershipOrganizationsRequest,
  verifyPartnershipCodeRequest,
  // metadata
  fetchMetadataRequest,
  // worker type
  saveWorkerClassificationRequest,
  getStateWorkerClassificationRequest,
  getWorkerClassificationManagement,
  saveChildOfficeWorkerClassification,
  saveStatusChildOfficeWorkerClassification,
  fetchHighlyRatedProfessionalsRequest,
};
