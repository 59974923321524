import { all, call, fork, put, select, take } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { LottieThumbUp } from '../images/lottie';
import { RemoteConfigUtils } from '../utils/RemoteConfigUtils';
import { getRedirectTarget } from '../utils/AdjustmentUtil';
import * as actions from '../actions';
import { custoSetUserProperties, logCustomEvent, trackEvent } from '../api/analytics';
import history from '../history';
import { apiMethods } from '../services/api';
import { apiMethodsConst } from '../services/methods';
import { store } from '../store/configureStore';
import { growthbook } from '../growthbook';

const REFERRAL_TYPE_1 = 'type1';

function* callAndDispatch(method, payload) {
  const response = yield call(apiMethods[method], payload);

  yield put({ type: method, payload: response });
}

function* callAndDispatchPayload(method, payload) {
  yield call(apiMethods[method], payload);
  yield put({ type: method, payload });
}

function* watchInitialLoading() {
  const events = actions.createRequestTypes(actions.INITIAL_LOADING);
  while (true) {
    yield take(actions.INITIAL_LOADING);

    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.AUTH_ME_REQUEST);
      const user = yield select((state) => state.user.user);
      if (user && user.id) {
        window.analytics.identify(user.id, {
          name: `${user.first_name || ''} ${user.last_name || ''}`,
          email: user.email_id,
          phone: user.contact_number || '',
          type: user.user_type || '',
        });

        Sentry.setUser({ email: user?.email_id, id: user?.id });

        yield* callAndDispatch(apiMethodsConst.FETCH_ALL_PP_JOBS);
        yield custoSetUserProperties(user);
        // adjustments request

        const jobsWithAdjustments = store.getState().job.adjustments;
        if (jobsWithAdjustments.length !== 0) {
          // TODO: uncomment this!
          history.push(`/dashboard/adjustments/${jobsWithAdjustments[0].id}`, {
            job_id: jobsWithAdjustments[0].id,
          });
        }
      }
      yield* callAndDispatch(apiMethodsConst.GET_STATE_WORKER_CLASSIFICATION_REQUEST);

      yield* callAndDispatch(apiMethodsConst.FETCH_AVERAGE_RATING);
      yield* callAndDispatch(apiMethodsConst.GET_ALL_PROFESSIONS_REQUEST);
      yield* callAndDispatch(apiMethodsConst.FETCH_ACTION_REQUIRED_JOBS_REQUEST, {
        page: 1,
        limit: 10,
      });

      yield put({ type: events.SUCCESS });
      if (user && !user.is_profile_complete) {
        history.push('/registration');
      }
    } catch (error) {
      if (
        history.location?.pathname !== '/signup' &&
        !history.location?.pathname?.includes('/forgotpassword') &&
        !history.location.search.includes('referral')
      ) {
        history.push('/');
      }
      if (error.response?.data.message !== 'No refresh token') {
        yield put({ type: events.FAILURE, payload: error.response?.data });
      }
      yield put({ type: events.FAILURE });
    }
  }
}

function* watchSignIn() {
  const events = actions.createRequestTypes(actions.SIGN_IN_ACTION);
  while (true) {
    const cred = yield take(actions.SIGN_IN_ACTION);

    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.SIGN_IN_REQUEST, cred);
      yield* callAndDispatch(apiMethodsConst.AUTH_ME_REQUEST);
      const user = yield select((state) => state.user.user);
      if (user.user_type !== 'DNT') {
        yield put({
          type: events.FAILURE,
          payload: {
            message:
              'Only dental offices are allowed to use the Dental Portal. Please, download the GoTu mobile application.',
          },
        });
      } else {
        yield put({ type: events.SUCCESS });
        yield* callAndDispatch(apiMethodsConst.FETCH_ALL_PP_JOBS);
        if (user?.is_profile_complete) {
          const stateToShowDualModelPopup = RemoteConfigUtils.getShowDualModelLaunchStates();
          const shouldDisplayDualModelPopupStates = stateToShowDualModelPopup.includes(
            user.address?.[0]?.state,
          );
          localStorage.setItem(
            `showDualModelTermsPopUp-${user?.id}`,
            shouldDisplayDualModelPopupStates,
          );
          yield* callAndDispatch(apiMethodsConst.GET_STATE_WORKER_CLASSIFICATION_REQUEST);
        }
        yield* callAndDispatch(apiMethodsConst.FETCH_AVERAGE_RATING);

        yield logCustomEvent('sign_in', {
          id: user.id,
          email: user.email_id,
          name: `${user.first_name} ${user.last_name}`,
        });
      }
      if (!user.is_profile_complete || typeof user.is_profile_complete === 'undefined') {
        history.push('/registration');
      }
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchSignUP() {
  const events = actions.createRequestTypes(actions.SIGN_UP_ACTION);
  while (true) {
    const cred = yield take(actions.SIGN_UP_ACTION);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.AUTH_SIGN_UP_REQUEST, cred);
      yield* callAndDispatch(apiMethodsConst.AUTH_ME_REQUEST);
      yield* callAndDispatch(apiMethodsConst.EDIT_USER_REQUEST, { user_type: 'DNT' });
      yield* callAndDispatch(apiMethodsConst.ACCEPT_TERMS_REQUEST);
      yield logCustomEvent('sign_up_started', {
        email: cred.email,
      });
      yield put({ type: events.SUCCESS });

      const user = yield select((state) => state.user.user);

      window.analytics.identify(user?.id, {
        email: cred.email,
        type: 'DNT',
      });

      trackEvent('Office Sign up - Account created');

      history.push('/registration');
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchEditUserAccountInfo() {
  const events = actions.createRequestTypes(actions.EDIT_ACCOUNT_INFO_ACTION);
  while (true) {
    const data = yield take(actions.EDIT_ACCOUNT_INFO_ACTION);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.EDIT_USER_REQUEST, data);
      if (data.place_id) {
        yield* callAndDispatch(apiMethodsConst.ADD_ADDRESS_REQUEST, data.place_id);
      }
      if (data.productivity) {
        yield* callAndDispatch(apiMethodsConst.ADD_OFFICE_PRODUCTIVITY_REQUEST, data.productivity);
      }
      if (data.avatar) {
        const formData = new FormData();
        formData.append('image', data.avatar);
        yield* callAndDispatch(apiMethodsConst.UPLOAD_AVATAR_REQUEST, formData);
      }
      if (data.contact_number) {
        yield* callAndDispatch(apiMethodsConst.SEND_SMS_CODE_REQUEST, data.contact_number);
      }
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response.data });
    }
  }
}

function* watchUploadAvatar() {
  const events = actions.createRequestTypes(actions.UPLOAD_AVATAR_ACTION);
  while (true) {
    const { avatar } = yield take(actions.UPLOAD_AVATAR_ACTION);
    yield put({ type: events.REQUEST });
    try {
      const formData = new FormData();
      formData.append('image', avatar);
      yield* callAndDispatch(apiMethodsConst.UPLOAD_AVATAR_REQUEST, formData);

      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response.data });
    }
  }
}

function* watchEditUserGeneralInfo() {
  const events = actions.createRequestTypes(actions.EDIT_GENERAL_INFO_ACTION);
  while (true) {
    const data = yield take(actions.EDIT_GENERAL_INFO_ACTION);
    yield put({ type: events.REQUEST });
    try {
      // edit
      yield* callAndDispatch(apiMethodsConst.EDIT_USER_REQUEST, data);

      history.push('/registration/admin');

      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchEditUserOfficeInfo() {
  const events = actions.createRequestTypes(actions.EDIT_USER_OFFICE_INFO);
  while (true) {
    const { data, insurances, jobId } = yield take(actions.EDIT_USER_OFFICE_INFO);
    yield put({ type: events.REQUEST });

    try {
      // edit
      yield* callAndDispatch(apiMethodsConst.EDIT_USER_REQUEST, { office_info: data });

      if (data?.education) {
        yield* callAndDispatch(apiMethodsConst.UPDATE_USER_EDUCATION_REQUEST, {
          education: data.education,
          insurances: insurances || [],
        });
      }

      if (jobId) {
        history.push(`/dashboard/job/${jobId}`, {
          jobId,
        });
      }
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchEditUser() {
  const events = actions.createRequestTypes(actions.EDIT_USER);
  while (true) {
    const { data, education, insurances, location } = yield take(actions.EDIT_USER);
    yield put({ type: events.REQUEST });

    try {
      // edit
      const user = store.getState().user?.user;
      const address = store.getState().user?.user?.address?.[0];
      if (data.place_id && address?.place_id !== data.place_id) {
        if (address) {
          yield* callAndDispatch(apiMethodsConst.DELETE_ADDRESSES_REQUEST);
        }
        yield* callAndDispatch(apiMethodsConst.ADD_ADDRESS_REQUEST, data.place_id);
      }

      if (data.productivity) {
        yield* callAndDispatch(apiMethodsConst.ADD_OFFICE_PRODUCTIVITY_REQUEST, data.productivity);
      }

      if (data.is_profile_complete) {
        localStorage.setItem(`showNewPhotoUploadFeaturePopup-${user?.id}`, 'false');
        localStorage.setItem(`showOfficeEfdaOnboardingPopUp-${user?.id}`, 'false');
        localStorage.setItem(`showOfficeFrontDeskOnboardingPopUp-${user?.id}`, 'false');
        localStorage.setItem(`isProfessionalHubNew-${user?.id}`, 'false');
        localStorage.setItem(`isHighlyRatedTabNew-${user?.id}`, 'false');
        localStorage.setItem(`isExpirationUpdatePopupNew-${user?.id}`, 'false');
        localStorage.setItem(`isRecentProfessionalsTabNew-${user.id}`, false);
        localStorage.setItem('tempmeeBecomingGoTuPopup', false);
        localStorage.setItem(`showNewBadgeAnnouncementPopUp-${user?.id}`, 'false');
        localStorage.setItem(`showDualModelTermsPopUp-${user?.id}`, 'false');
        localStorage.setItem(`showNewAutoConfirmOptionPopup-${user?.id}`, 'false');
        localStorage.setItem(`selectWorkerTypePopup-${user?.id}`, 'false');
      }

      if (education) {
        yield* callAndDispatch(apiMethodsConst.UPDATE_USER_EDUCATION_REQUEST, {
          education,
          insurances,
        });
      }

      yield* callAndDispatch(apiMethodsConst.EDIT_USER_REQUEST, data);

      if (data.is_profile_complete) {
        yield* callAndDispatch(
          apiMethodsConst.AUTH_REFRESH_REQUEST,
          localStorage.getItem('refresh_token_dental'),
        );
      }

      if (location) {
        history.push(location);
      }

      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchBlockProfessional() {
  const events = actions.createRequestTypes(actions.BLOCK_PROFESSIONAL);
  while (true) {
    const { user } = yield take(actions.BLOCK_PROFESSIONAL);
    yield put({ type: events.REQUEST, payload: user.id });
    try {
      yield* callAndDispatch(apiMethodsConst.BLOCK_PROFESSIONAL_REQUEST, { userId: user.id });

      if (user?.isFavorite) {
        yield* callAndDispatch(apiMethodsConst.DELETE_FAVORITE_PROFESSIONAL_REQUEST, user.id);
      }

      yield put({ type: events.SUCCESS });
      yield put({
        type: actions.SHOW_MESSAGE,
        payload: {
          title: user?.isFavorite
            ? `${user.first_name} ${user.last_name} has successfully been removed from your Favorites List and is now blocked.`
            : `You have successfully blocked ${user.first_name} ${user.last_name}`,
          message:
            'This professional will no longer be able to see or be matched to shifts posted by this office in the future.',
          isSuccess: true,
        },
      });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchBlockRecentProfessional() {
  const events = actions.createRequestTypes(actions.BLOCK_RECENT_PROFESSIONAL);
  while (true) {
    const { user } = yield take(actions.BLOCK_RECENT_PROFESSIONAL);
    yield put({ type: events.REQUEST, payload: user.id });
    try {
      yield* callAndDispatch(apiMethodsConst.BLOCK_PROFESSIONAL_REQUEST, { userId: user.id });
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchAuthLogout() {
  const events = actions.createRequestTypes(actions.LOGOUT_ACTION);
  while (true) {
    yield take(actions.LOGOUT_ACTION);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.AUTH_LOGOUT_REQUEST);
      growthbook.setAttributes({});
      history.push('/');
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchPhoneVerification() {
  const events = actions.createRequestTypes(actions.PHONE_VERIFICATION_ACTION);
  while (true) {
    const data = yield take(actions.PHONE_VERIFICATION_ACTION);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.PHONE_VERIFICATION_REQUEST, data.code);
      yield* callAndDispatch(apiMethodsConst.AUTH_ME_REQUEST);
      yield* callAndDispatch(apiMethodsConst.FETCH_ALL_PP_JOBS);
      yield* callAndDispatch(apiMethodsConst.FETCH_AVERAGE_RATING);

      history.push('/registration/office-info');
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchCloseModalWithCode() {
  const events = actions.createRequestTypes(actions.CLOSE_MODAL_ACTION);
  while (true) {
    yield take(actions.CLOSE_MODAL_ACTION);
    yield put({ type: events.REQUEST });
    try {
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchfeedbackRequired() {
  const events = actions.createRequestTypes(actions.FEEDBACK_REQUIRED);
  while (true) {
    // const feedbackRequiredDate =  store.getState().feedback

    yield take(actions.FEEDBACK_REQUIRED);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.FEEDBACK_REQUEST);
      yield put({ type: events.SUCCESS });

      const job = store.getState().feedback.feedbackRequired?.[0];
      const { id: jobId, adjustments } = job;

      if (!jobId || !adjustments) {
        return;
      }

      const redirectTarget = getRedirectTarget(adjustments);
      if (redirectTarget === 'feedback-rating') {
        history.push(`/dashboard/job/${jobId}/feedback-rating`);
      } else if (redirectTarget === 'feedback') {
        history.push(`/dashboard/job/${jobId}/feedback`);
      }
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchFeedbackSave() {
  const events = actions.createRequestTypes(actions.FEEDBACK_SAVE_ACTION);
  while (true) {
    const { data, jobId, isFromProfessionalHub } = yield take(actions.FEEDBACK_SAVE_ACTION);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatchPayload(apiMethodsConst.FEEDBACK_SAVE_REQUEST, { data, jobId });
      const isNPSNeeded = store.getState().user.user.show_nps;
      yield put({
        type: actions.SHOW_MESSAGE,
        payload: {
          message:
            'Your feedback has been saved. Thank you for helping us improve your experience.',
          title: 'Feedback Successfully Sent!',
          route: isNPSNeeded ? '/dashboard/nps' : '/',
          isSuccess: true,
        },
      });

      yield logCustomEvent('send_review', {
        ...data,
        jobId,
      });

      trackEvent('Review submitted', {
        rate: data.rating_count,
        'hire again': data.like_to_hire_again,
      });

      yield put({ type: events.SUCCESS });

      if (isFromProfessionalHub) {
        history.push('/professionals-hub/recent-professionals');
      }
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error });
    }
  }
}

function* watchSendProcedureEndorsement() {
  const events = actions.createRequestTypes(actions.SEND_PROCEDURE_ENDORSEMENT);

  while (true) {
    const { endorsement, userId } = yield take(actions.SEND_PROCEDURE_ENDORSEMENT);

    yield put({ type: events.REQUEST });

    try {
      yield* callAndDispatchPayload(apiMethodsConst.SEND_PROCEDURE_ENDORSEMENT_REQUEST, {
        endorsement,
        userId,
      });

      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error });
    }
  }
}

function* watchFetchUserProcedureEndorsement() {
  const events = actions.createRequestTypes(actions.FETCH_USER_PROCEDURE_ENDORSEMENT);

  while (true) {
    const { candidateId, profession } = yield take(actions.FETCH_USER_PROCEDURE_ENDORSEMENT);

    yield put({ type: events.REQUEST });

    try {
      yield* callAndDispatch(apiMethodsConst.FETCH_USER_PROCEDURE_ENDORSEMENT_REQUEST, {
        candidateId,
        profession,
      });

      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error });
    }
  }
}

function* watchSaveNpsReview() {
  const events = actions.createRequestTypes(actions.SEND_NPS_REVIEW);
  while (true) {
    const { npsScore } = yield take(actions.SEND_NPS_REVIEW);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatchPayload(apiMethodsConst.SEND_NPS_REQUEST, npsScore);
      if (npsScore === -1) {
        history.push('/');
      } else {
        yield put({
          type: actions.SHOW_MESSAGE,
          payload: {
            message: 'Your feedback has been saved',
            route: '/',
            isSuccess: true,
          },
        });
      }
      yield logCustomEvent('nps_review', {
        npsScore,
      });
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

export function* watchPostFeedbackTempmee() {
  const events = actions.createRequestTypes(actions.SEND_FEEDBACK_TEMPMEE);
  while (true) {
    const { message } = yield take(actions.SEND_FEEDBACK_TEMPMEE);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatchPayload(apiMethodsConst.SEND_TEMPMEE_FEEDBACK_REQUEST, message);
      yield put({
        type: actions.SHOW_MESSAGE,
        payload: {
          popupProps: {
            Icon: LottieThumbUp,
            rightButtonText: 'All Done!',
          },
          title: 'Thanks for your feedback!',
          message: (
            <span>
              Your feedback means a lot to us. You’re helping us keep
              <br />
              things shining, one smile at a time!
            </span>
          ),
          route: '/account',
        },
      });
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watcOfficeSelect() {
  const events = actions.createRequestTypes(actions.SELECT_OFFICE);
  while (true) {
    const { officeId } = yield take(actions.SELECT_OFFICE);
    yield put({ type: events.SUCCESS, payload: officeId });

    yield* callAndDispatch(apiMethodsConst.GET_SELECTED_OFFICE_PROFILE_REQUEST, officeId);

    yield* callAndDispatch(apiMethodsConst.GET_STATE_WORKER_CLASSIFICATION_REQUEST);
    history.push('/');
  }
}

function* watcSearchChildOffice() {
  while (true) {
    const events = actions.createRequestTypes(actions.SEARCH_OFFICE_ACTION);
    const { searchText } = yield take(actions.SEARCH_OFFICE_ACTION);
    yield put({ type: events.SUCCESS, payload: searchText });
  }
}

function* watchGetAllProfessionals() {
  const events = actions.createRequestTypes(actions.GET_ALL_PROFESSIONS_ACTION);
  while (true) {
    yield take(actions.GET_ALL_PROFESSIONS_ACTION);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.GET_ALL_PROFESSIONS_REQUEST);
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchGetAllSpecializations() {
  const events = actions.createRequestTypes(actions.GET_ALL_SPECIALIZATIONS_ACTION);
  while (true) {
    yield take(actions.GET_ALL_SPECIALIZATIONS_ACTION);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.GET_ALL_SPECIALIZATIONS_REQUEST);
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchGetUserEducation() {
  const events = actions.createRequestTypes(actions.GET_USER_EDUCATION_ACTION);

  while (true) {
    const { profession } = yield take(actions.GET_USER_EDUCATION_ACTION);

    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.GET_USER_EDUCATION_REQUEST, { profession });
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchGetOfficeEducation() {
  const events = actions.createRequestTypes(actions.GET_OFFICE_EDUCATION_ACTION);

  while (true) {
    yield take(actions.GET_OFFICE_EDUCATION_ACTION);

    yield put({ type: events.REQUEST });

    try {
      yield* callAndDispatch(apiMethodsConst.GET_OFFICE_EDUCATION_REQUEST);
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchUpdateUserEducation() {
  const events = actions.createRequestTypes(actions.UPDATE_USER_EDUCATION_ACTION);
  while (true) {
    const data = yield take(actions.UPDATE_USER_EDUCATION_ACTION);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.UPDATE_USER_EDUCATION_REQUEST, data);
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchGetAllSoftwares() {
  const events = actions.createRequestTypes(actions.GET_ALL_SOFTWARES_ACTION);
  while (true) {
    yield take(actions.GET_ALL_SOFTWARES_ACTION);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.GET_ALL_SOFTWARES_REQUEST);
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchAcceptTerms() {
  const events = actions.createRequestTypes(actions.ACCEPT_TERMS_ACTION);
  while (true) {
    const { officeId } = yield take(actions.ACCEPT_TERMS_ACTION);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.ACCEPT_TERMS_REQUEST);
      yield* callAndDispatch(apiMethodsConst.GET_SELECTED_OFFICE_PROFILE_REQUEST, officeId);
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchAcceptPSATerms() {
  const events = actions.createRequestTypes(actions.ACCEPT_PSA_TERMS_ACTION);
  while (true) {
    const { officeId } = yield take(actions.ACCEPT_PSA_TERMS_ACTION);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.ACCEPT_PSA_TERMS_REQUEST);
      yield* callAndDispatch(apiMethodsConst.AUTH_ME_REQUEST);
      yield* callAndDispatch(apiMethodsConst.GET_SELECTED_OFFICE_PROFILE_REQUEST, officeId);

      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchFetchMetadata() {
  const events = actions.createRequestTypes(actions.FETCH_METADATA);
  while (true) {
    yield take(actions.FETCH_METADATA);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.FETCH_METADATA_REQUEST);
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchSaveWorkerClassification() {
  const events = actions.createRequestTypes(actions.SAVE_WORKER_CLASSIFICATION);
  while (true) {
    const { payload } = yield take(actions.SAVE_WORKER_CLASSIFICATION);
    yield put({ type: events.REQUEST });

    try {
      yield* callAndDispatch(apiMethodsConst.SAVE_WORKER_CLASSIFICATION_REQUEST, { type: payload });

      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchGetStateWorkerClassification() {
  const events = actions.createRequestTypes(actions.GET_STATE_WORKER_CLASSIFICATION);
  while (true) {
    yield take(actions.GET_STATE_WORKER_CLASSIFICATION);
    yield put({ type: events.REQUEST });

    try {
      yield* callAndDispatch(apiMethodsConst.GET_STATE_WORKER_CLASSIFICATION_REQUEST);

      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchGetWorkerClassificationManagement() {
  const events = actions.createRequestTypes(actions.GET_WORKER_CLASSIFICATION_MANAGEMENT);
  while (true) {
    yield take(actions.GET_WORKER_CLASSIFICATION_MANAGEMENT);
    yield put({ type: events.REQUEST });

    try {
      yield* callAndDispatch(apiMethodsConst.GET_WORKER_CLASSIFICATION_MANAGEMENT);

      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchSaveChildWorkerClassification() {
  const events = actions.createRequestTypes(actions.SAVE_CHILD_WORKER_CLASSIFICATION);
  while (true) {
    const payload = yield take(actions.SAVE_CHILD_WORKER_CLASSIFICATION);

    yield put({ type: events.REQUEST });

    try {
      yield* callAndDispatch(apiMethodsConst.SAVE_CHILD_WORKER_CLASSIFICATION, payload);
      yield* callAndDispatch(apiMethodsConst.GET_WORKER_CLASSIFICATION_MANAGEMENT);

      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchSaveStatusChildWorkerClassification() {
  const events = actions.createRequestTypes(actions.SAVE_STATUS_CHILD_WORKER_CLASSIFICATION);
  while (true) {
    const payload = yield take(actions.SAVE_STATUS_CHILD_WORKER_CLASSIFICATION);

    yield put({ type: events.REQUEST });

    try {
      yield* callAndDispatchPayload(
        apiMethodsConst.SAVE_STATUS_CHILD_WORKER_CLASSIFICATION,
        payload,
      );
      yield* callAndDispatch(apiMethodsConst.GET_WORKER_CLASSIFICATION_MANAGEMENT);

      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchFetchNotificationSettings() {
  const events = actions.createRequestTypes(actions.FETCH_NOTIFICATION_SETTINGS);
  while (true) {
    yield take(actions.FETCH_NOTIFICATION_SETTINGS);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.FETCH_NOTIFICATION_SETTINGS_REQUEST);
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchUpdateNotificationSettings() {
  const events = actions.createRequestTypes(actions.UPDATE_NOTIFICATION_SETTINGS);
  while (true) {
    const { notificationSettings, callback } = yield take(actions.UPDATE_NOTIFICATION_SETTINGS);
    yield put({ type: events.REQUEST });

    try {
      yield* callAndDispatch(
        apiMethodsConst.UPDATE_NOTIFICATION_SETTINGS_REQUEST,
        notificationSettings,
      );

      yield put({ type: events.SUCCESS, payload: notificationSettings });
      callback();
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchSaveGiftEmail() {
  const events = actions.createRequestTypes(actions.SAVE_GIFT_EMAIL);
  while (true) {
    try {
      const { email } = yield take(actions.SAVE_GIFT_EMAIL);
      yield put({ type: events.REQUEST });
      yield* callAndDispatch(apiMethodsConst.SAVE_GIFT_EMAIL_REQUEST, email);
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchCreateReferralLink() {
  const events = actions.createRequestTypes(actions.CREATE_REFERRAL_LINK);

  while (true) {
    yield take(actions.CREATE_REFERRAL_LINK);

    try {
      const type = REFERRAL_TYPE_1;

      // 1. try to get presaved dynamic link from the API

      const getReferralLinkResponce = yield call(
        apiMethods[apiMethodsConst.GET_REFERRALS_REQUEST],
        type,
      );

      //  2. get the referral id if there are no lick from API
      let referralLink = '';
      let referralId = '';
      let referralInfo = {};

      if (getReferralLinkResponce.status === 400 || !getReferralLinkResponce.data?.url) {
        // 3. get the firebase link with refferal id
        const createReferralResponse = yield call(
          apiMethods[apiMethodsConst.CRETE_REFERRALS_REQUEST],
          type,
        );

        referralId = createReferralResponse.referral_id;
        referralInfo = createReferralResponse;
        // 3. get the firebase link with refferal id
        const url = yield call(apiMethods[apiMethodsConst.CREATE_FIREBASE_LINK], referralId);

        referralLink = url.shortLink;

        // 4. save firebase link at the server
        const putReferralLinkResponse = yield call(
          apiMethods[apiMethodsConst.PUT_REFERRAL_LINK_REQUEST],
          referralId,
          referralLink,
          type,
        );
        referralInfo = putReferralLinkResponse.data;
      } else if (getReferralLinkResponce.status === 200) {
        referralLink = getReferralLinkResponce.data.url;
        referralInfo = getReferralLinkResponce.data;
      }
      yield put({ type: events.SUCCESS, payload: { referralLink, referralInfo } });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchFetchPartnershipOrganizations() {
  const events = actions.createRequestTypes(actions.FETCH_PARTNERSHIP_ORGANIZATIONS);
  while (true) {
    yield take(actions.FETCH_PARTNERSHIP_ORGANIZATIONS);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.FETCH_PARTNERSHIP_ORGANIZATIONS_REQUEST);
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchVerifyPartnershipCode() {
  const events = actions.createRequestTypes(actions.VERIFY_PARTNERSHIP_CODE);
  while (true) {
    const { code, organization } = yield take(actions.VERIFY_PARTNERSHIP_CODE);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.VERIFY_PARTNERSHIP_CODE_REQUEST, {
        partnerReferral: {
          code,
          organization,
        },
      });

      yield put({ type: events.SUCCESS, payload: { code, organization } });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchForgotPasswordSendCode() {
  const events = actions.createRequestTypes(actions.FORGOT_PASSWORD_SEND_CODE_ACTION);
  while (true) {
    const data = yield take(actions.FORGOT_PASSWORD_SEND_CODE_ACTION);
    yield put({ type: events.REQUEST });
    try {
      const method = apiMethodsConst.FORGOT_PASSWORD_REQUEST.SEND_CODE;
      const payload = data;
      yield* callAndDispatch(method, { [payload.method]: payload.value });
      yield put({ type: method, payload: { ...payload } });
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchForgotPasswordReset() {
  const events = actions.createRequestTypes(actions.FORGOT_PASSWORD_RESET_ACTION);
  while (true) {
    const data = yield take(actions.FORGOT_PASSWORD_RESET_ACTION);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.FORGOT_PASSWORD_REQUEST.RESET, data);
      yield put({ type: events.SUCCESS });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

// use them in parallel
export default function* sagaAuth() {
  yield all([
    fork(watchInitialLoading),
    fork(watchSignIn),
    fork(watchSignUP),
    fork(watchAuthLogout),
    fork(watchEditUserAccountInfo),
    fork(watchEditUserGeneralInfo),
    fork(watchPhoneVerification),
    fork(watchCloseModalWithCode),
    fork(watchEditUserOfficeInfo),
    fork(watchEditUser),
    fork(watchBlockProfessional),
    fork(watchfeedbackRequired),
    fork(watchFeedbackSave),
    fork(watchSendProcedureEndorsement),
    fork(watchFetchUserProcedureEndorsement),
    fork(watchSaveNpsReview),
    fork(watchPostFeedbackTempmee),
    fork(watcOfficeSelect),
    fork(watcSearchChildOffice),
    fork(watchGetAllProfessionals),
    fork(watchSaveGiftEmail),
    fork(watchCreateReferralLink),
    fork(watchGetAllSpecializations),
    fork(watchGetUserEducation),
    fork(watchGetOfficeEducation),
    fork(watchUpdateUserEducation),
    fork(watchGetAllSoftwares),
    fork(watchAcceptTerms),
    fork(watchAcceptPSATerms),
    fork(watchFetchNotificationSettings),
    fork(watchUpdateNotificationSettings),
    fork(watchFetchPartnershipOrganizations),
    fork(watchVerifyPartnershipCode),
    fork(watchFetchMetadata),
    fork(watchSaveWorkerClassification),
    fork(watchGetStateWorkerClassification),
    fork(watchGetWorkerClassificationManagement),
    fork(watchSaveChildWorkerClassification),
    fork(watchSaveStatusChildWorkerClassification),
    fork(watchForgotPasswordSendCode),
    fork(watchForgotPasswordReset),
    fork(watchUploadAvatar),
    fork(watchBlockRecentProfessional),
  ]);
}
