import '../../../themes/global.scss';
import '../../../themes/home.scss';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SELECT_DEFAULT_INFO_MDB,
  getUserEducation,
  getUserOvertimeLimits,
  selectDaysMDB,
  selectDefaultInfoMDB,
} from '../../../actions';
import { trackEvent } from '../../../api/analytics';
import { createLoadingSelector } from '../../../api/selectors';
import BackButton from '../../../commonComponents/BackButton';
import Button from '../../../commonComponents/Button';
import CardSpinner from '../../../commonComponents/CardSpinner';
import { UserProfession, UserSubProfession } from '../../../enums/UserProfession';
import { getShouldOfficeShowEfda } from '../../../growthbook';
import { getAllProfessionTypeOptions } from '../../../utils/DentistUtils';
import { EnumHelper } from '../../../utils/EnumHelper';
import EditShiftMDB from './EditShiftMDB';
import Form from './Form';
import ProgressBar from './ProgressBar';
import NewAutoConfirmOptionPopup from './components/NewAutoConfirmOptionPopup';

const loadingSelector = createLoadingSelector([SELECT_DEFAULT_INFO_MDB]);

export default function SelectDefaultMDB({ history }) {
  const editedDate = history.location?.state?.editedDate || '';
  const selectedDate = history.location?.state?.selectedDate;

  const currentJob = useSelector(
    (state) => state.multiday.jobInfo.filter((job) => job.localDate === editedDate)[0],
  );
  const selectedChildOffice = useSelector((state) => state.user.selectedChildOffice);
  const initialBreak = currentJob?.break_time === 0 ? '0 min' : currentJob?.break_time;

  const allProfessions = useSelector((state) => state.user.allProfessions);
  const showEfda = getShouldOfficeShowEfda();
  const allProfessionOptions = getAllProfessionTypeOptions(allProfessions, { showEfda });

  const educationInfo = selectedChildOffice?.office_info?.education;
  const defaultSpecialty =
    Object.keys(educationInfo?.specialtiesAndProcedures || {})[0] || 'specialty_General Dentistry';
  const user = useSelector((state) => state.user.selectedChildOffice);

  const [formValues, setFormValues] = useState({
    offerStartTime: currentJob?.localStart || null,
    offerEndTime: currentJob?.localEnd || null,
    rate: currentJob?.rate || 0,
    lunch: initialBreak || '',
    isLunchPaid: currentJob?.is_break_paid || false,
    professionType:
      UserSubProfession.getName(currentJob?.subProfession) ??
      UserProfession.getName(currentJob?.profession) ??
      UserProfession.getName(UserProfession.RDH),
    skills: defaultSpecialty || currentJob?.skills || 'specialty_General Dentistry',
    enableAutoFill: currentJob?.autoFill || false,
    enableAutoFillFavorites: currentJob?.autoFillFavorites || false,
    enableAutoFillHighlyRated: currentJob?.autoFillHighlyRated || false,
    procedures: currentJob?.procedures || [],
    isAssistedHygiene:
      currentJob?.isAssistedHygiene || selectedChildOffice.office_info.isAssistedHygiene,
  });

  const selectedProfessionCode = allProfessionOptions?.filter(
    (item) => item.name === formValues.professionType,
  )?.[0]?.code;

  const isLoading = useSelector((state) => loadingSelector(state));
  const dispatch = useDispatch();
  const jobDates = useSelector((state) => state.multiday.jobDates);
  const jobInfo = useSelector((state) => state.multiday.jobInfo);

  const isDisabled =
    !formValues.offerStartTime ||
    !formValues.offerEndTime ||
    formValues.rate === 0 ||
    !formValues.lunch ||
    (selectedProfessionCode === UserProfession.RDH && formValues.isAssistedHygiene === undefined) ||
    !jobDates?.length;

  useEffect(() => {
    setFormValues((prevState) => ({
      ...prevState,
      skills: defaultSpecialty,
      procedures: [],
      enableAutoFill: false,
    }));

    const professionCode = UserProfession.getNormalizedCode(
      UserProfession.getCode(formValues.professionType),
    );

    dispatch(getUserEducation({ profession: professionCode }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.professionType]);

  useEffect(() => {
    setFormValues((prevState) => ({ ...prevState, procedures: [] }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.skills]);

  const nexBtnClicked = () => {
    const jobData = {
      localStart: formValues.offerStartTime,
      localEnd: formValues.offerEndTime,
      rate: formValues.rate,
      is_break_paid: formValues.isLunchPaid,
      break_time: formValues.lunch,
      // use profession. normalized if sub-profession is included
      profession: UserProfession.getNormalizedCode(selectedProfessionCode),

      // include sub-profession if it's a member of UserSubProfession
      subProfession: EnumHelper.getObjectKeys(UserSubProfession).includes(selectedProfessionCode)
        ? selectedProfessionCode
        : null,

      specialty: formValues.skills || 'specialty_General Dentistry',
      autoFill: formValues.enableAutoFill,
      autoFillFavorites: formValues.enableAutoFillFavorites,
      autoFillHighlyRated: formValues.enableAutoFillHighlyRated,
      procedures: formValues.procedures,
      ...(selectedProfessionCode === UserProfession.RDH && {
        isAssistedHygiene: formValues.isAssistedHygiene,
      }),
    };

    dispatch(selectDefaultInfoMDB({ jobData }));
    trackEvent('Temp Request - Shift Details Entry "Next" Clicked', { jobData });
    history.push('/dashboard/multi-day/confirm');
  };

  useEffect(() => {
    if (!user?.overtimeLimits) {
      dispatch(getUserOvertimeLimits());
    }
  }, [dispatch, user?.overtimeLimits]);

  /*
    Add date selected in Calendar screen or erase the date state
  */
  useEffect(() => {
    if (!jobInfo?.length) {
      dispatch(
        selectDaysMDB({ jobData: selectedDate ? { [selectedDate]: new Date(selectedDate) } : [] }),
      );
    }
  }, [jobInfo, selectedDate, dispatch]);

  if (isLoading) {
    return <CardSpinner />;
  }

  if (editedDate && currentJob) {
    return <EditShiftMDB currentJob={currentJob} />;
  }

  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{ justifyContent: 'flex-start', flexDirection: 'column' }}
      >
        <div className="card">
          <div style={{ marginTop: 20, marginLeft: 30 }}>
            <BackButton />
          </div>
          <div className="card-body" style={{ margin: 30 }}>
            <ProgressBar step={1} />

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                marginTop: 60,
              }}
            >
              <Form formValues={formValues} setFormValues={setFormValues} />
            </div>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 120,
            marginTop: 20,
          }}
        >
          <Button text="Next" onClick={nexBtnClicked} disabled={isDisabled} />
        </div>
      </div>

      <NewAutoConfirmOptionPopup />
    </div>
  );
}
