import { motion, AnimatePresence } from 'framer-motion';
import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import history from '../../../../history';
import { Colors } from '../../../../themes/colors';
import { useFixedWidth } from '../../hooks/useFixedWidth';
import { CollapsibleCandidateCard } from './CollapsibleCandidateCard';
import { useJobCandidates } from '../../hooks/useJobCandidates';
import { getShouldEnableUnlimitedCandidatesList } from '../../../../growthbook';
import LeftArrowIcon from '../../../../images/circle-left-arrow-icon.svg';
import RightArrowIcon from '../../../../images/circle-right-arrow-icon.svg';

export const CollapsibleCandidateSelection = ({ show }) => {
  const { jobId, candidateId } = useParams();
  const { allCandidates } = useSelector((state) => state.job);
  const parentRef = useRef(null);
  const fixedWidth = useFixedWidth(parentRef);
  const {
    candidates,
    bestCandidates,
    isFirstPage,
    isLastPage,
    loadPrevPageCandidates,
    loadNextPageCandidates,
  } = useJobCandidates();

  const [isHovered, setIsHovered] = useState(false);

  const isUnlimitedCandidatesListEnabled = getShouldEnableUnlimitedCandidatesList();

  const handleOnClick = (id) => {
    history.replace(`/dashboard/job/${jobId}/confirm/${id}`);
  };

  const isBestPick = useCallback(
    (candidateId) => bestCandidates.some((candidate) => candidate?.id === candidateId),
    [bestCandidates],
  );

  if (allCandidates?.length === 1) {
    return null;
  }

  return (
    <div
      ref={parentRef}
      style={{
        position: 'relative',
      }}
    >
      <AnimatePresence>
        {show && (
          <motion.div
            style={{
              width: fixedWidth,
              position: 'fixed',
              top: 64,
              zIndex: 10,
              paddingTop: 10,
              backgroundColor: Colors.white,
              boxShadow:
                '0px 3px 10px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
            }}
            initial={{ opacity: 0, y: 0 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 0 }}
            transition={{ duration: 0.3 }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div
              style={{
                position: 'sticky',
                top: 400,
                zIndex: 1000,
                backgroundColor: Colors.white,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  gap: 8,
                  position: 'sticky',
                  zIndex: 10,
                }}
              >
                {isUnlimitedCandidatesListEnabled && !isFirstPage && (
                  <img
                    src={LeftArrowIcon}
                    alt="left-arrow"
                    style={{
                      cursor: 'pointer',
                      position: 'absolute',
                      zIndex: 1,
                      left: -12,
                      transform: 'scale(0.7)',
                      opacity: isHovered ? 1 : 0,
                      transition: 'opacity 0.3s ease',
                    }}
                    onClick={loadPrevPageCandidates}
                  />
                )}

                {candidates?.map((item) => (
                  <motion.div
                    key={item.id}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8 }}
                  >
                    <CollapsibleCandidateCard
                      item={item}
                      isSelected={candidateId === item?.candidate?.id}
                      onClick={() => handleOnClick(item?.candidate?.id)}
                      isBestPick={isBestPick(item?.candidate?.id)}
                    />
                  </motion.div>
                ))}

                {isUnlimitedCandidatesListEnabled && !isLastPage && (
                  <img
                    src={RightArrowIcon}
                    alt="right-arrow"
                    style={{
                      cursor: 'pointer',
                      position: 'absolute',
                      zIndex: 1,
                      right: 0,
                      marginRight: 8,
                      transform: 'scale(0.7)',
                      opacity: isHovered ? 1 : 0,
                      transition: 'opacity 0.3s ease',
                    }}
                    onClick={loadNextPageCandidates}
                  />
                )}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
