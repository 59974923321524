import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useLoading from '../../../hooks/useLoading';
import {
  FETCH_JOB_CANDIDATES_ACTION,
  fetchJobCandidates,
  showJobCandidatesForPage,
} from '../../../actions';

export const useJobCandidates = () => {
  const dispatch = useDispatch();

  const { visibleCandidates, visibleCandidatesPagination, bestPickCandidates } = useSelector(
    (state) => state.job,
  );

  const { page, totalPages } = visibleCandidatesPagination || {};

  const { isLoading } = useLoading([FETCH_JOB_CANDIDATES_ACTION]);

  const fetchCandidates = useCallback(
    (jobId, { reset } = {}) => {
      dispatch(fetchJobCandidates({ jobId, reset }));
    },
    [dispatch],
  );

  const loadPrevPageCandidates = useCallback(() => {
    dispatch(showJobCandidatesForPage(page - 1));
  }, [dispatch, page]);

  const loadNextPageCandidates = useCallback(() => {
    dispatch(showJobCandidatesForPage(page + 1));
  }, [dispatch, page]);

  const isFirstPage = page === 1;
  const isLastPage = page === totalPages;

  return {
    fetchCandidates,
    loadPrevPageCandidates,
    loadNextPageCandidates,
    candidates: visibleCandidates,
    bestCandidates: bestPickCandidates,
    isLoading,
    isFirstPage,
    isLastPage,
  };
};
