import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE_JOB_FROM_MDB, deleteJobFromMDB, selectDaysMDB } from '../../../actions';
import { trackEvent } from '../../../api/analytics';
import history from '../../../history';
import useLoading from '../../../hooks/useLoading';
import { handleConfirmRemoveDate, isDailyOvertime } from '../multiday/utils';

const actions = [DELETE_JOB_FROM_MDB];

export const useReviewStep = ({ urlPrefix = '' }) => {
  const dispatch = useDispatch();
  const [showDeleted, setShowDeleted] = useState(false);
  const [deletedJob, setDeletedJob] = useState(null);
  const [showOvertimeLimitPopup, setShowOvertimeLimitPopup] = useState(false);
  const jobDates = useSelector((state) => state.multiday.jobDates);
  const user = useSelector((state) => state.user.selectedChildOffice);
  const workerClassificationType = user?.workerClassification?.classificationType;
  const overtimeLimits = user?.overtimeLimits?.[workerClassificationType];
  const jobInfo = useSelector((state) => state.multiday.jobInfo);
  const { isLoading } = useLoading(actions);
  const hasOvertimeDay = jobInfo.some((job) => isDailyOvertime(job, overtimeLimits?.daily));

  const handleButtonClick = ({ alertOvertime = true }) => {
    if (alertOvertime && overtimeLimits && hasOvertimeDay) {
      setShowOvertimeLimitPopup(true);
      return;
    }

    trackEvent("Re-Book Request - Review Screen - “Send Request” Clicked", { jobInfo })
    history.push(`${urlPrefix}/guidelines`);
  };

  const editPressed = (editedDate) => {
    history.push(`${urlPrefix}/info`, { editedDate });
  };

  const confirmRemoveDate = () => {
    const jobData = handleConfirmRemoveDate(jobDates, deletedJob);
    dispatch(selectDaysMDB({ jobData }));
    dispatch(deleteJobFromMDB({ deletedJob }));
  };

  const deletePressed = (jobDate) => {
    setDeletedJob(jobDate);
    setShowDeleted(true);
  };

  return {
    jobDates,
    isLoading,
    jobInfo,
    showDeleted,
    deletedJob,
    showOvertimeLimitPopup,
    handleButtonClick,
    editPressed,
    confirmRemoveDate,
    deletePressed,
    setShowDeleted,
    setShowOvertimeLimitPopup,
  };
};
