import defaultProfile from '../../../../images/HY_tooth.png';
import HeartBorderedIcon from '../../../../images/icons/HeartBorderedIcon';
import { Colors } from '../../../../themes/colors';
import { BestPickLabel } from './BestPickLabel';

export const CollapsibleCandidateCard = ({ item, onClick, isSelected = false, isBestPick }) => (
  <div
    style={{
      backgroundColor: isSelected ? Colors.white : Colors.neutral_50,
      boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.15)',
      width: 208,
      height: 60,
      cursor: 'pointer',
    }}
    onClick={onClick}
  >
    <div
      style={{
        position: 'relative',
        height: 4,
        backgroundColor: isSelected || isBestPick ? Colors.secondary_500 : 'transparent',
        width: 'calc(100% + 2px)',
        marginLeft: -1,
      }}
    >
      {isBestPick && <BestPickLabel />}
    </div>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        paddingTop: 4,
        paddingLeft: 16,
      }}
    >
      <div
        style={{
          position: 'relative',
          border: `3px solid ${item?.isFavorite ? Colors.favorites_400 : 'transparent'}`,
          width: 36,
          height: 36,
          borderRadius: 62,
          alignItems: 'center',
          justifyContent: 'center',
          padding: 2,
        }}
      >
        {item?.candidate && item?.candidate.profile_image ? (
          <img
            src={item?.candidate.profile_image}
            style={{
              width: 36,
              height: 36,
              borderRadius: 31,
              objectFit: 'cover',
            }}
            alt="profile_image"
          />
        ) : (
          <div className="default_circle_container">
            <img src={defaultProfile} alt="profile_image" width="36px" />
          </div>
        )}
        {item?.isFavorite && (
          <div style={{ position: 'absolute', top: '62%', right: -2 }}>
            <HeartBorderedIcon width={14} />
          </div>
        )}
      </div>

      <b
        style={{
          fontSize: 16,
          color: Colors.neutral_600,
        }}
      >
        {`${item?.candidate?.first_name} ${item?.candidate?.last_name}`}
      </b>
    </div>
  </div>
);
