import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import {
  DELETE_ALL_ALERTS_ACTION,
  DELETE_ONE_ALERT_ACTION,
  GET_ALL_ALERTS_ACTION,
  GET_USER_MESSAGES,
  deleteAllAlerts,
  deleteOneAlert,
  getAlerts,
  getUserMessages,
  hideMessage,
  selectContentTypeInbox,
  showMessage,
} from '../actions';
import { trackEvent } from '../api/analytics';
import { createLoadingSelector } from '../api/selectors';
import { Text } from '../commonComponents';
import { enableChatsUnread, enableLastJobPostedReminders, enablePaymentExpirationReminders, enableProfileCompletenessReminders } from '../growthbook';
import { Colors } from '../themes/colors';
import Alerts from './Alerts';
import { Notification } from './Inbox/Notification/Notification';
import { NotificationType } from './Inbox/Notification/Type';
import Messages from './messages/Messages';
import { Chat } from './Inbox/Chat/Chat';
import { useInbox } from './Inbox/hooks/useInbox';
import { useChatsUnreadCount } from './Inbox/Chat/hooks/useChatsUnreadCount';
import { useNotificationCount } from './Inbox/Notification/hooks/useNotificationCount';
import { useTaskDeletion } from './Inbox/Notification/Tasks/hooks/useTaskDeletion';

const LIMIT_VALUE = 15;
export default ({ history, isRootComponent = true }) => {
  const selectedContent = useSelector((state) => state.messages.selectedContent);
  const conversationCount = useSelector((state) => state.user.user.conversationCount);
  const dashboardInfo = useSelector((state) => state.job.dashboardInfo);

  const isFinalPage = useSelector((state) => state.messages.isFinalPage);
  const isFinalPageAlerts = useSelector((state) => state.alerts.isFinalPage);

  const isActiveRequest = useSelector((state) => state.messages.isActiveRequest);
  const isActiveRequestAlerts = useSelector((state) => state.alerts.isActiveRequest);

  const selectedOfficeId = useSelector((state) => state.user.selectedOfficeId);

  const totalUnread = useChatsUnreadCount();
  const totalNotifications = useNotificationCount();
  const { handleDeleteAll, handleDeleteOne } = useTaskDeletion();

  const isEnableChatUnread = useRef(enableChatsUnread()).current;
  const isEnableLastJobPostedReminders = useRef(enableLastJobPostedReminders()).current;
  const isEnablePaymentExpirationReminders = useRef(enablePaymentExpirationReminders()).current;
  const isEnableProfileCompletenessReminders = useRef(enableProfileCompletenessReminders()).current;

  const isEnableNotificationReminder = 
    isEnableLastJobPostedReminders || 
    isEnablePaymentExpirationReminders || 
    isEnableProfileCompletenessReminders;

  const isAlertContent = selectedContent === NotificationType.Alert;
  const isMessageContent = selectedContent === NotificationType.Message;

  const dispatch = useDispatch();

  const loadingSelector = createLoadingSelector([
    GET_ALL_ALERTS_ACTION,
    GET_USER_MESSAGES,
    DELETE_ALL_ALERTS_ACTION,
    DELETE_ONE_ALERT_ACTION,
  ]);

  const { page } = useSelector((store) => store.messages.searchParams);
  const searchParamsAlerts = useSelector((store) => store.alerts.searchParams);

  const isLoading = useSelector((state) => loadingSelector(state));
  const refMessages = useRef(null);
  const refAlerts = useRef(null);

  const handleScrollMessages = () => {
    const messagesScroll = refMessages?.current;

    const userScrollHeight = messagesScroll?.scrollHeight - messagesScroll?.scrollTop;
    const clientHeight = messagesScroll?.clientHeight;

    if (userScrollHeight <= clientHeight && !isActiveRequest) {
      dispatch(getUserMessages({ page: page + 1, limit: LIMIT_VALUE }));
    }
  };

  const handleScrollAlerts = () => {
    const alertsScroll = refAlerts.current;

    const userScrollHeight = alertsScroll?.scrollHeight - alertsScroll?.scrollTop;
    const clientHeight = alertsScroll?.clientHeight + 20;

    if (userScrollHeight <= clientHeight && !isActiveRequestAlerts && !isFinalPageAlerts) {
      dispatch(getAlerts({ page: searchParamsAlerts?.page + 1, limit: LIMIT_VALUE }));
    }
  };

  const { getMessages, getNotifications } = useInbox();

  useEffect(() => {
    if (isAlertContent && !isEnableNotificationReminder) {
      dispatch(getAlerts({ page: 1, limit: LIMIT_VALUE }));
    } else if (isMessageContent && !isEnableChatUnread) {
      dispatch(getUserMessages({ page: 1, limit: LIMIT_VALUE }));
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOfficeId]);

  const selectInboxType = (type) => {
    if (type === 'alerts') {
      getNotifications();
      dispatch(selectContentTypeInbox({ payload: 'alerts' }));
    } else if (type === 'messages') {
      getMessages();
      dispatch(selectContentTypeInbox({ payload: 'messages' }));
    }
  };

  const deleteAll = () => {
    dispatch(
      showMessage({
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete all your messages?',
        isError: true,
        popupProps: {
          leftButtonText: 'Go Back',
          leftButtonAction: () => dispatch(hideMessage()),
          rightButtonText: 'Remove',
          rightButtonAction: () => {
            dispatch(deleteAllAlerts());
            dispatch(hideMessage());
          },
        },
      }),
    );
  };

  const selectedAlert = (job) => {
    // TODO: - follow new alert schema
    if (job.job_path === 'DNT_OFFER') {
      trackEvent('Office Dashboard - Inbox Notifications Tile Clicked', {
        actionNeededShiftsCount: dashboardInfo?.actionRequired,
        scheduledShiftsCount: dashboardInfo?.scheduled,
        openShiftsCount: dashboardInfo?.activeRequest,
      });

      history.push(`/dashboard/job/${job.joboffer_id}`, {
        jobId: job.joboffer_id,
      });
    }
  };

  const deleteOneAlertSubmit = (alertId) => {
    dispatch(deleteOneAlert({ alertId }));
  };

  const selectedMessage = (job) => {
    trackEvent('Office Dashboard - Inbox Chats Tile Clicked', {
      actionNeededShiftsCount: dashboardInfo?.actionRequired,
      scheduledShiftsCount: dashboardInfo?.scheduled,
      openShiftsCount: dashboardInfo?.activeRequest,
    });

    history.push(`/dashboard/chat/${job.id}`, { jobId: job.id });
  };

  const renderTabUnderline = () => (
    <div
      style={{
        height: 2,
        display: 'flex',
        width: '100%',
        backgroundColor: Colors.secondary_500,
        marginTop: 5,
      }}
    />
  )

  const renderContent = () => {
    if (isAlertContent) {
      if (isEnableNotificationReminder) {
        return <Notification />;
      }

      return (
        <div ref={refAlerts} onScroll={handleScrollAlerts} style={{ overflowY: 'scroll', maxHeight: 500 }}>
          <Alerts
            isLoading={isLoading}
            selectedJobs={selectedAlert}
            deleteAll={handleDeleteAll}
            deleteOneAlert={handleDeleteOne}
          />
          {!isFinalPageAlerts && isLoading ? (
            <div
              style={{
                width: '100%',
                height: 100,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ClipLoader sizeUnit="px" size={50} color={Colors.primary_500} loading />
            </div>
          ) : (
            <div />
          )}
        </div>
      );
    }

    if (isMessageContent) {
      if (isEnableChatUnread) {
        return <Chat />
      }

      return (
        <div
          ref={refMessages}
          onScroll={handleScrollMessages}
          style={{ overflowY: 'scroll', maxHeight: 500 }}
        >
          <Messages
            isLoading={isLoading}
            selectedJobs={selectedMessage}
            deleteAll={deleteAll}
            deleteOneAlert={deleteOneAlertSubmit}
          />
          {!isFinalPage && isLoading ? (
            <div
              style={{
                width: '100%',
                height: 100,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ClipLoader sizeUnit="px" size={50} color={Colors.primary_500} loading />
            </div>
          ) : (
            <div />
          )}
        </div>
      );
    }

    return null
  };

  return (
    <div
      className="inbox"
      style={{
        borderRadius: isRootComponent && 
          (isEnableChatUnread || isEnableNotificationReminder) 
          ? 0 : 25,
      }}
    >
      <div style={{ width: '100%', margin: '15px 25px' }}>
        <h1
          className="global_font f-dark bold"
          style={{
            fontSize: 32,
            textAlign: 'center',
            marginRight: 20,
            marginLeft: 30,
            marginBottom: 0,
          }}
        >
          Inbox
        </h1>
        <div
          style={{
            width: '90%',
            display: 'flex',
            alignItems: 'baseline',
            gap: 8,
          }}
        >
          <div style={{marginLeft: 20 }}>
            {isEnableNotificationReminder ? (
              <div
                style={{
                  display: 'flex',
                  gap: 4,
                  alignItems: 'baseline',
                }}
              >
                <button
                  className="global_font"
                  style={{
                    background: 'none',
                    color: isAlertContent ? Colors.neutral_600 : Colors.neutral_300,
                    cursor: 'pointer',
                    fontSize: 22,
                    fontWeight: isAlertContent ? 'bold' : 'normal',
                    textAlign: 'center',
                    position: 'relative',
                  }}
                  type='button'
                  onClick={() => selectInboxType('alerts')}
                >
                  Notifications
                  {isAlertContent && renderTabUnderline()}
                </button>
                <div
                  style={{
                    alignItems: 'center',
                    backgroundColor: isAlertContent ? Colors.primary_500 : Colors.primary_100,
                    borderRadius: 4,
                    display: 'flex',
                    height: totalNotifications > 99 ? 28 : 24,
                    justifyContent: 'center',
                    width: totalNotifications > 99 ? 28 : 24,
                  }}
                >
                  <Text
                    bold
                    color={isAlertContent ? Colors.white : Colors.primary_500}
                    fontSize={totalNotifications > 99 ? 12 : 14}
                  >
                    {totalNotifications}
                  </Text>
                </div>
              </div>
            ) : (
              <>
                <h4
                  onClick={() => selectInboxType('alerts')}
                  className={`global_font ${isAlertContent ? 'f-dark ' : 'f-light'} bold`}
                  style={{
                    fontSize: 24,
                    fontWeight: isAlertContent ? 'bold' : 'normal',
                    marginRight: 20,
                    cursor: 'pointer',
                    marginBottom: isAlertContent ? 0 : 7,
                    textAlign: 'center',
                    marginLeft: 20,
                  }}
                >
                  Notifications
                </h4>
                {isAlertContent ? renderTabUnderline() : (
                  <div />
                )}
              </>
            )}
          </div>
          <div>
            {isEnableChatUnread ? (
              <div
                style={{
                  display: 'flex',
                  gap: 4,
                  alignItems: 'baseline',
                }}
              >
                <button
                  className="global_font"
                  style={{
                    background: 'none',
                    color: isMessageContent ? Colors.neutral_600 : Colors.neutral_300,
                    cursor: 'pointer',
                    fontSize: 22,
                    fontWeight: isMessageContent ? 'bold' : 'normal',
                    textAlign: 'center',
                    position: 'relative',
                  }}
                  type='button'
                  onClick={() => selectInboxType(NotificationType.Message)}
                >
                  Chats
                  {isMessageContent && (
                    <span
                      style={{
                        height: 2.5,
                        backgroundColor: Colors.secondary_500,
                        width: '100%',
                        position: 'absolute',
                        display: 'block',
                        bottom: 0,
                        left: 0,
                      }}
                    />
                  )}
                </button>
                <div
                  style={{
                    alignItems: 'center',
                    backgroundColor: isMessageContent ? Colors.primary_500 : Colors.primary_100,
                    borderRadius: 4,
                    display: 'flex',
                    height: totalUnread > 99 ? 28 : 24,
                    justifyContent: 'center',
                    width: totalUnread > 99 ? 28 : 24,
                  }}
                >
                  <Text
                    bold
                    color={isMessageContent ? Colors.white : Colors.primary_500}
                    fontSize={totalUnread > 99 ? 12 : 14}
                  >
                    {totalUnread}
                  </Text>
                </div>
              </div>
            ) : (
              <>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <h4
                    onClick={() => {
                      selectInboxType('messages');
                    }}
                    className={`global_font ${
                  isMessageContent ? 'f-dark ' : 'f-light'
                } bold`}
                    style={{
                      fontSize: 24,
                      fontWeight: isMessageContent ? 'bold' : 'normal',
                      cursor: 'pointer',
                      marginLeft: 20,
                      marginRight: 20,
                      marginBottom: isMessageContent ? 0 : 7,
                    }}
                  >
                    Chats
                  </h4>
                  {conversationCount ? (
                    <div
                      style={{
                        backgroundColor: '#f4511d',
                        borderRadius: 50,
                        width: 10,
                        height: 10,
                        marginRight: 5,
                        marginTop: 20,
                      }}
                    />
                  ) : (
                    <div />
                  )}
                </div>
                {isMessageContent ? (
                  <div
                    style={{
                      height: 2,
                      display: 'flex',
                      width: '100%',
                      backgroundColor: Colors.secondary_500,
                      marginTop: 5,
                    }}
                  />
                ) : (
                  <div />
                )}
              </>
            )}
          </div>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 20,
          marginBottom: 20,
          marginTop: 20,
          width: '100%',
        }}>
          {renderContent()}
        </div>
      </div>
    </div>
  );
};
