import { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_ALL_PAYMENTS_ACTION, getAllPaymnets } from "../../../../../actions";
import { enablePaymentExpirationReminders, getHideNotificationsOnParentOffice } from "../../../../../growthbook";
import useLoading from "../../../../../hooks/useLoading";

export const usePaymentExpiration = () => {
  const dispatch = useDispatch();

  const isEnablePaymentExpiration = useRef(enablePaymentExpirationReminders()).current;
  const isHideNotificationsOnParentOffice = useRef(getHideNotificationsOnParentOffice()).current;

  const {isLoading} = useLoading([GET_ALL_PAYMENTS_ACTION]);
  const payments = useSelector((state) => state.payments.allPayments);
  const user = useSelector((state) => state.user.selectedChildOffice || state.user.user);
  const isParentOffice = useSelector((state) => 
    (state.user?.selectedChildOffice?.childOffices || state.user?.childOffices)?.length > 1
  );
  const isParentOfficeAndHideNotificationsOnParentOffice =
    isParentOffice && isHideNotificationsOnParentOffice;


  useEffect(() => {
    if (
      isEnablePaymentExpiration &&
      !user?.is_pre_approved &&
      !isParentOfficeAndHideNotificationsOnParentOffice
    ) {
      dispatch(getAllPaymnets())
    }
  }, [
    dispatch,
    isEnablePaymentExpiration,
    isParentOfficeAndHideNotificationsOnParentOffice,
    user?.is_pre_approved,
  ]);

  const defaultPaymentMethod = useMemo(() => payments?.
    find((payment) => payment.is_selected),
  [payments]);
  const isBankAccount = defaultPaymentMethod?.type === "bank_account";

  const {isExpired, isExpiring} = useMemo(() => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1;

    const isExpired = defaultPaymentMethod?.expiry_year < currentYear || 
        (
          defaultPaymentMethod?.expiry_year === currentYear &&
            defaultPaymentMethod?.expiry_month < currentMonth
        );

    let isExpiring = false

    if (!isExpired) {
      const monthsUntilExpiry = ((defaultPaymentMethod?.expiry_year - currentYear) * 12) + 
        (defaultPaymentMethod?.expiry_month - currentMonth);
      isExpiring = monthsUntilExpiry <= 2;
    }

    return {
      isExpired,
      isExpiring,
    }
  }, [defaultPaymentMethod])

  if (
    !isEnablePaymentExpiration ||
    isParentOfficeAndHideNotificationsOnParentOffice ||
    isBankAccount
  ) {
    return {
      isLoading: false,
      isPaymentExpired: false,
      isPaymentExpiring: false,
    }
  }

  return {
    isLoading,
    isPaymentExpired: !isLoading && !user?.is_pre_approved && isExpired,
    isPaymentExpiring: !isLoading && !user?.is_pre_approved && isExpiring,
  }
}